import React from "react"
import { Modal } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import tw, { css } from "twin.macro"

const CloseIcon = () => (
  <CloseOutlined
    style={{
      fontSize: "12px",
      verticalAlign: "middle",
    }}
  />
)

interface CustomModalProps {
  isVisible: boolean
  width?: number
  title?: React.ReactNode
  onCancel?:
    | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined
  footer?: React.ReactNode
  customCss?: any
  children: any
}

const CustomModal = ({
  isVisible,
  title,
  onCancel,
  footer,
  width = 620,
  children,
  customCss,
}: CustomModalProps): React.ReactElement => (
  <Modal
    title={title}
    centered
    visible={isVisible}
    onCancel={onCancel}
    closeIcon={<CloseIcon />}
    footer={footer}
    width={width}
    css={[
      css`
        & .ant-modal-content {
          border-radius: 20px;
        }
        & .ant-modal-header {
          padding: 16px 0px;
          border-radius: 20px 20px 0px 0px;
          background-color: #efefef;
          border-bottom: 1px solid #a0aec0;
        }
        & .ant-modal-close-x {
          height: 52px;
          line-height: 52px;
        }
        & .ant-modal-body {
          padding: 0px;
        }
        & .ant-modal-footer {
          padding: 0px;
          border-top: none;
          background-color: #efefef;
          border-radius: 0px 0px 20px 20px;
        }
      `,
      customCss,
    ]}
  >
    {children}
  </Modal>
)

// eslint-disable-next-line react/display-name
CustomModal.Title = ({
  heading,
  alignCenter = false,
  children,
}: {
  heading: string
  alignCenter?: boolean
  children: any
}): React.ReactElement => (
  <div
    css={[
      tw`flex justify-between px-16 items-center`,
      alignCenter && tw`justify-center`,
    ]}
  >
    <span css={tw`text-gray-900 text-lg font-bold`}>{heading}</span>
    {children}
  </div>
)

// eslint-disable-next-line react/display-name
CustomModal.Footer = ({
  heading,
  children,
  headingCss,
  customCss,
}: {
  heading: string
  children: any
  headingCss?: any
  customCss?: any
}): React.ReactElement => (
  <div css={[tw`flex py-3 px-16 justify-between items-center`, customCss]}>
    <div
      css={[tw`text-orange-1 text-xs font-bold`, headingCss]}
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        __html: heading,
      }}
    />
    {children}
  </div>
)

export default CustomModal
